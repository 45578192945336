import React from "react"
import styled from "styled-components"

import AboutLayout from "../../components/about/AboutLayout"
import QuoteImage from "../../components/QuoteImage"
import BackgroundImage from "../../components/BackgroundImage"
import TestimonialImage from "../../components/TestimonialImage"

import SEO from "../../components/seo"

import testimonials from "../../content/quotes"

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  section {
    margin: 2.4rem 0;
    box-sizing: border-box;
    width: 100%;

    &.references {
      max-width: 900px;
      margin: 2.4rem auto;

      p {
        font-size: 2.4rem;
      }
    }

    &.testimonial {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 40rem;

      &.odd {
        font-size: 3.6rem;

        .quote {
          color: #fff;
        }

        .image-and-quote {
          position: absolute;
          margin: 0 auto;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
      }

      &.even {
        display: flex;
        align-items: center;
        justify-content: center;

        &.right {
          .quote {
            margin-left: 4.8rem;
          }
        }

        &.left {
          .quote {
            margin-right: 4.8rem;
          }
        }

        .image-and-quote {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2em 0;

          .quote {
            width: 600px;
            /* margin: inherit; */

            blockquote {
              font-size: 2.4rem;
              word-wrap: break-word;
            }
          }
        }
      }
    }

    .quote {
      font-family: "Special Elite", cursive;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 900px;

      blockquote {
        font-weight: 100;
        line-height: 1.4;
        position: relative;
        padding: 0.5rem;

        /* Account for pseudo element offsets */
        margin: 2rem 5rem;

        &:before,
        &:after {
          position: absolute;
          color: #f1efe6;
          font-size: 8rem;
          width: 4rem;
          height: 4rem;
        }

        &:before {
          content: "“";
          left: -5rem;
          top: -2rem;
        }

        &:after {
          content: "”";
          right: -5rem;
          bottom: 1rem;
        }
      }
    }

    .cite-container {
      font-family: "Special Elite", cursive;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 2.2rem;
      padding-right: 4em;

      cite {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        /* line-height: 3; */
        text-align: left;
        /* display: inline-block; */

        .cite-author {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }

        .gatsby-image-wrapper {
          height: 3.2rem;
          width: 3.2rem;
          border-radius: 50%;
          margin-right: 1.2rem;

          img {
            margin-bottom: 0;
          }
        }
      }
    }

    &.odd {
      .cite-container {
        .cite-author {
          color: #fff;
        }
      }
    }

    @media (max-width: 940px) {
      max-width: 600px;

      &.testimonial {
        &.odd,
        &.even {
          font-size: 3rem;

          .quote {
            width: 560px;

            blockquote {
              margin: 2rem;
            }
          }

          .cite-container {
            padding: 0;
          }
        }

        &.odd {
          font-size: 3rem;
        }

        &.even {
          font-size: 1.6rem;
          margin: 0 auto;

          &.right {
            .image {
              margin-bottom: 2em;
            }
          }

          &.left {
            .image {
              margin-top: 2em;
            }
          }

          .image-and-quote {
            flex-direction: column;

            .quote {
              width: 480px;
              margin: 0 auto;
              position: relative;

              blockquote: {
                margin-top: 4em;
              }
            }
          }
        }
      }
    }

    @media (max-width: 600px) {
      &.references {
        padding: 0 1em;
        h1 {
          font-size: 2.4rem;
        }

        p {
          font-size: 1.8rem;
        }
      }

      &.testimonial {
        &.even,
        &.odd {
          font-size: 1.8rem;

          .image-and-quote {
            .quote {
              width: 100%;

              blockquote {
                margin: 2em 0.6em;
                font-size: 1.8rem;

                &:before {
                  left: 0;
                  top: -6.4rem;
                }

                &:after {
                  right: 0;
                  bottom: -3rem;
                }
              }
            }

            .cite-container {
              justify-content: center;
              margin-top: 2em;
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
`

// Have the non-background image elements zig zag which wise picture is on
let alignedLeft = [],
  alignedRight = [],
  shouldRightAlign = true

const evenElements = testimonials
  .map((testimonial, i) => i)
  .filter(i => i % 2 !== 0)

evenElements.forEach(i => {
  shouldRightAlign ? alignedRight.push(i) : alignedLeft.push(i)
  shouldRightAlign = !shouldRightAlign
})

const PageBody = ({ testimonial, i }) => {
  return (
    <section
      className={`testimonial ${testimonial.id} ${
        evenElements.includes(i) ? "even" : "odd"
      } ${alignedLeft.includes(i) ? "left" : ""} ${
        alignedRight.includes(i) ? "right" : ""
      }`}
      key={testimonial.id}
    >
      <div className="image-and-quote">
        {alignedRight.includes(i) ? (
          <div className="image">
            <TestimonialImage author={testimonial.id}></TestimonialImage>
          </div>
        ) : (
          undefined
        )}

        <div className="quote-and-cite">
          <div className="quote">
            <blockquote
              dangerouslySetInnerHTML={{ __html: testimonial.quote }}
            ></blockquote>
          </div>
          <div className="cite-container">
            <cite>
              <QuoteImage author={testimonial.id} />
              <div className="cite-author">{testimonial.author}</div>
            </cite>
          </div>
        </div>

        {alignedLeft.includes(i) ? (
          <div className="image">
            <TestimonialImage author={testimonial.id}></TestimonialImage>
          </div>
        ) : (
          undefined
        )}
      </div>
    </section>
  )
}

const TestimonialsPage = () => (
  <AboutLayout>
    <SEO title="stevecreswick.com"></SEO> />
    <Styled>
      <BackgroundImage key={"baby"} author={"baby"}>
        <section className="testimonial odd">
          <div className="image-and-quote">
            <div className="quote-and-cite">
              <div className="quote">
                <blockquote
                  dangerouslySetInnerHTML={{ __html: "hello" }}
                ></blockquote>
              </div>
              <div className="cite-container">
                <cite>
                  <QuoteImage author={"caesar"} />
                  <div className="cite-author">{"caesar"}</div>
                </cite>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      {testimonials.map((testimonial, i) =>
        !evenElements.includes(i) ? (
          <BackgroundImage key={testimonial.id} author={testimonial.id}>
            <PageBody testimonial={testimonial} i={i} />
          </BackgroundImage>
        ) : (
          <PageBody key={testimonial.id} testimonial={testimonial} i={i} />
        )
      )}
    </Styled>
  </AboutLayout>
)

export default TestimonialsPage
